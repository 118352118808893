import React from 'react'

declare global {
  interface Window {
    __theme?: string
    __onThemeChange?: () => void;
    __setPreferredTheme(theme: string): void
  }
}

export enum Theme {
  Light = "light",
  Dark = "dark"
}

type ChildProps = {
  theme: Theme | null | undefined
  toggleTheme: (theme: Theme) => any
}

interface Props {
  children: (props: ChildProps) => any
}

class ThemeToggler extends React.Component<Props, {theme: Theme | null | undefined}> {
  state = {
    theme: typeof window !== 'undefined' ? window.__theme as Theme : null,
  }

  componentDidMount() {
    window.__onThemeChange = () => {
      this.setState({ theme: window.__theme as Theme })
    }
  }

  toggleTheme(theme: Theme) {
    window.__setPreferredTheme(theme)
  }

  render() {
    return (
      <this.props.children
        theme={this.state.theme}
        toggleTheme={this.toggleTheme}
      />
    )
  }
}

export default ThemeToggler