/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { graphql, StaticQuery } from "gatsby"

import Header from "./header"

import ThemeToggler, { Theme } from "./ThemeToggler"

import "./styles/layout.scss"

type ChildProps = {
  theme: Theme
  toggleTheme: (theme: Theme) => any
}

interface LayoutProps {
  children: (props: ChildProps) => any
  currentPath: string
  readingMode?: boolean
}

class Layout extends React.Component<LayoutProps> {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                owner
                sections
              }
            }
          }
        `}
        render={ data => (
          <ThemeToggler>
            {({ theme, toggleTheme }) => {
              // Don't render anything at compile time. Deferring rendering until we
              // know which theme to use on the client avoids incorrect initial
              // state being displayed.
              if (theme == null) {
                return null
              }
      
              return (
                <>
                  <Header currentPath={this.props.currentPath} theme={theme} sections={data.site.siteMetadata?.sections || []} />
            
                  <div className={ this.props.readingMode ? `layout-container--compact` : `layout-container`}>
                    <main>
                      {this.props.children({theme, toggleTheme})}
                    </main>
                  </div>

                  <footer className={`layout-footer`}>
                    <div className={`layout-footer__container--${theme}`}>
                      Copyright © {new Date().getFullYear()} {data.site.siteMetadata?.owner || ""}
                    </div>
                  </footer>
                </>
              )
            }}
          </ThemeToggler>
        )}
      />
    )
  }
}

export default Layout
