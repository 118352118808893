import * as React from "react"
import PropTypes, { InferProps } from "prop-types"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "./styles/header.scss"

function classesForItem(isActive: boolean, theme: string): string {
  let activeClass = isActive ? `menu-item_link--active--${theme}` : ""
  return `menu-item_link ${activeClass}`
}

function NavigationItem({ item, isActive, theme }: InferProps<typeof NavigationItem.propTypes>) {
  return (
    <li className={`menu-item--${theme}`} onClick={() => navigate(`/${item}/`) }>
      <Link to={`/${item}/`} className={classesForItem(isActive, theme)}>
        {item.toLocaleUpperCase()}
      </Link>
    </li>
  )
}

NavigationItem.propTypes = {
  item: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired
}

function Header({ currentPath, theme, sections }: InferProps<typeof Header.propTypes>) {
  return (
    <header className={`nav-container--${theme}`}>
      <nav className="nav-header">
        <Link
          to="/"
          className="nav-header_home-link"
        >
          <StaticImage
            alt="site logo"
            className="nav-header_home-icon"
            formats={["auto", "webp", "avif"]}
            layout="constrained"
            loading="eager"
            placeholder="blurred"
            quality={100}
            src="../images/dabby_logo.png"
            width={80}
          />
        </Link>

        <div className="nav-header_flex-space" />

        <div className="nav-header_menu">
          <div className="menu">
            <div className="menu-icon">
              <span className={`menu-icon_burger--${theme}`}></span>
            </div>

            <div className={`menu-content-container--${theme}`}>
              <ul className="menu-content">
                {
                  sections.map((item) => (
                    <NavigationItem
                      key={item}
                      item={item}
                      isActive={currentPath.trim().startsWith(`/${item}`)}
                      theme={theme}
                    />
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  currentPath: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
}

export default Header
